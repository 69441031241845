<template>
  <div id="borrow-history">
    <div class="head-options">
      <div class="row">
        <div class="col-12 col-lg-9 mt-3 mb-3">
          <div class="d-flex align-items-end flex-wrap gap-3">
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn d-flex my-1"
              @click="onExport"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p class="m-0">{{ $t("depreciation.export_file") }}</p>
            </button>
            <div class="d-flex flex-wrap gap-4">
              <div class="d-flex flex-column">
                <p style="color: #7c7c7c; text-align: left;">
                  {{ $t("borrow.borrow_filter_start") }}
                </p>
                <date-picker
                  v-model="filter.start_datetime"
                  locale="en"
                  mode="dateTime"
                  is24hr
                  :minute-increment="60"
                  :masks="masks"
                  @input="loadAsset"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <input
                      class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      readonly
                      @click="togglePopover()"
                    />
                  </template>
                </date-picker>
              </div>
              <div class="d-flex flex-column">
                <p style="color: #7c7c7c; text-align: left;">
                  {{ $t("borrow.borrow_filter_end") }}
                </p>
                <date-picker
                  v-model="filter.end_datetime"
                  locale="en"
                  mode="dateTime"
                  is24hr
                  :minute-increment="60"
                  :min-date="filter.start_datetime"
                  :validHours="hourIsValidEnd"
                  :masks="masks"
                  @input="loadAsset"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <input
                      class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      readonly
                      @click="togglePopover()"
                    />
                  </template>
                </date-picker>
              </div>
            </div>
            <div>
              <select
                v-model="filter.status"
                class="form-control h-100"
                style="background-color: #E5E7E7 !important;"
                @change="filterByStatus"
              >
                <option :value="null">{{ $t("filter.all") }}</option>
                <option
                  v-for="status in queryStatuses"
                  :key="status"
                  :value="status"
                  >{{ getStatusText(status) }}</option
                >
              </select>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-lg-3">
          <div
            class="d-flex align-items-center justify-content-end gap-3 h-100"
          >
            <div class="position-relative" style="height: fit-content">
              <input
                type="text"
                v-model.trim="filter.text"
                v-debounce:700ms="loadAsset"
                :placeholder="$t('borrow.search')"
                class="form-control"
                style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
              />
              <img
                class="position-absolute top-50 translate-middle-y"
                style="left: 5px"
                src="../../assets/search-white.svg"
                width="20px"
              />
            </div>
            <button
              class="filter flex-shrink-0"
              type="button"
              id="button-addon2"
              @click="filterModal = true"
            >
              <img src="@/assets/filter-white.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <manage-order-table
      @interface="getOrderTableInterface"
      :assetData="tableData"
      :otherColumn="otherColumn"
      :page="currentPage"
      :perPage="perPage"
      :rowSelection="'none'"
      @detail-click="showAssetDetail"
      @note-click="showAssetNote"
    ></manage-order-table>
    <div class="d-flex justify-content-center mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
        @input="loadAsset"
      ></b-pagination>
    </div>
    <base-dialog
      :showModal="detailModal"
      hideButton
      showCloseButton
      @hide-modal="detailModal = false"
    >
      <div class="mx-3" id="detailModal">
        <p class="text-center">{{ $t("home.as_dt") }}</p>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.fixedas_num") }}</p>
          <p>{{ assetDetailInModal.id_fixed_asset }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.as_name") }}</p>
          <p>{{ assetDetailInModal.name }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.as_dt") }}</p>
          <p>{{ assetDetailInModal.description }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.cat") }}</p>
          <p>{{ assetDetailInModal.category }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.sub_cat") }}</p>
          <p>{{ assetDetailInModal.sub_category }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.as_loc") }}</p>
          <div class="lh-1">
            <p>
              {{ $t("detailasset.build") }}
              {{ assetDetailInModal.location_building }}
              {{ assetDetailInModal.location_floor }}
            </p>
            <p>
              {{ $t("detailasset.dep") }}
              {{ assetDetailInModal.location_department }}
              {{ $t("detailasset.zone") }}
              {{ assetDetailInModal.location_zone }}
            </p>
          </div>
        </div>
      </div>
    </base-dialog>
    <base-dialog
      :showModal="noteModal"
      hideButton
      showCloseButton
      @hide-modal="noteModal = false"
    >
      <div class="mx-3">
        <p class="text-center">{{ $t("borrow.note") }}</p>
        <div class="asset-info my-4">
          <p class="label">{{ $t("borrow.borrow_note") }}</p>
          <p>{{ assetDetailInModal.borrow_note }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("borrow.return_note") }}</p>
          <p>{{ assetDetailInModal.return_note }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("borrow.status_note") }}</p>
          <p>
            {{
              notAvalilableStatusTranslate(
                assetDetailInModal.not_available_note
              )
            }}
          </p>
        </div>
      </div>
    </base-dialog>
    <asset-filter
      :showModal="filterModal"
      @set-filter="setDefaultFilter"
      @confirm-action="handleFilter"
      @cancel-action="handleClear"
      @hide-modal="filterModal = false"
    ></asset-filter>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ManageOrderTable from "../../components/Table/BorrowReturn/ManageOrderTable.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import BaseDialog from "../../components/BaseDialog.vue";
import AssetFilter from "../../components/BorrowReturn/AssetFilter.vue";
import { BorrowReturnStatuses } from "../../constants/borrow-return/BorrowReturnStatuses";
import { authHeader } from "@/store/actions";
import { borrowUrl } from "@/util/backend";
import { statusOptionsMixin } from "@/mixin/BorrowReturn/statusOptionsMixin";
import { mapGetters } from "vuex";

export default {
  name: "BorrowHistory",
  components: {
    ManageOrderTable,
    DatePicker,
    AssetFilter,
    BaseDialog,
  },
  mixins: [statusOptionsMixin],
  data() {
    return {
      orderTableFunction: {
        refreshCells: () => {},
        showLoading: () => {},
        hideLoading: () => {},
      },
      perPage: 10,
      tableData: null,
      otherColumn: [],
      queryStatuses: [
        BorrowReturnStatuses.RETURNED,
        BorrowReturnStatuses.RETURNED_DAMAGED,
        BorrowReturnStatuses.RETURNED_OVERDUE,
        BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE,
        BorrowReturnStatuses.LOST,
        BorrowReturnStatuses.CANCEL,
      ],
      totalRows: 0,
      currentPage: 1,
      assetDetailInModal: {
        id_fixed_asset: "",
        name: "",
        category: "",
        description: "",
        sub_category: "",
        location_building: "",
        location_zone: "",
        location_floor: "",
        location_department: "",
        borrow_note: "",
        return_note: "",
        not_available_note: "",
      },
      detailModal: false,
      noteModal: false,
      masks: {
        inputDateTime24hr: "DD/MM/YYYY HH:mm",
      },
      filterModal: false,
      filter: {
        start_datetime: null,
        end_datetime: null,
        text: null,
        status: null,
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
    }),
  },
  methods: {
    notAvalilableStatusTranslate(value) {
      switch (value) {
        case "DAMAGED":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.DAMAGED"
          )}`;
        case "LOST":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.LOST"
          )}`;
        case "OVERDUE":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.OVERDUE"
          )}`;
        default:
          return "-";
      }
    },
    hourIsValidEnd(hour, dateParts) {
      if (moment(dateParts.date).isSame(this.filter.start_datetime, "d")) {
        return hour > +moment(this.filter.start_datetime).format("H");
      } else {
        return true;
      }
    },
    setDefaultFilter(filterValue) {
      this.filterOptions = filterValue;
    },
    handleFilter(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.loadAsset();
    },
    handleClear(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.filter.text = "";
      this.loadAsset();
    },
    filterByStatus() {
      this.loadAsset();
    },
    getOrderTableInterface(childInterface) {
      this.orderTableFunction = childInterface;
    },
    async loadAsset() {
      this.orderTableFunction.showLoading();
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.id_company = id_company;

      const response = await axios.get(
        `${borrowUrl()}asset/list/request/company/id/${id_company}`,
        {
          params: {
            ...this.filter,
            ...this.filterOptions,
            status: this.filter.status
              ? [this.filter.status]
              : this.queryStatuses,
            page: this.currentPage,
            limit: this.perPage,
          },
          ...authHeader(),
        }
      );

      const data = response.data;
      this.tableData = data.results;
      this.totalRows = data.item_count;
      this.orderTableFunction.hideLoading();
    },
    showAssetDetail(assetDetail) {
      this.setAssetDetailInModal(assetDetail);
      this.detailModal = true;
    },
    setAssetDetailInModal(assetDetail) {
      this.assetDetailInModal = {
        id_fixed_asset: assetDetail.asset.id_fixed_asset,
        name: assetDetail.asset.name,
        description: assetDetail.asset?.description || "-",
        category: assetDetail.asset?.category
          ? assetDetail.asset.category.name
          : "-",
        sub_category: assetDetail.asset?.sub_category
          ? assetDetail.asset.sub_category.name
          : "-",
        location_building: assetDetail.asset?.location_building
          ? assetDetail.asset.location_building.name
          : "-",
        location_zone: assetDetail.asset?.location_zone
          ? assetDetail.asset.location_zone.name
          : "-",
        location_floor: assetDetail.asset?.location_floor
          ? assetDetail.asset.location_floor.name
          : "-",
        location_department: assetDetail.asset?.location_department
          ? assetDetail.asset.location_department.name
          : "-",
        borrow_note: assetDetail?.borrow_note || "-",
        return_note: assetDetail?.return_note || "-",
        not_available_note: assetDetail?.not_available_note || "-",
      };
    },
    showAssetNote(assetDetail) {
      this.setAssetDetailInModal(assetDetail);
      this.noteModal = true;
    },
    async onExport() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${borrowUrl()}asset/list/request/excel/company/id/${id_company}`,
          {
            params: {
              ...this.filter,
              ...this.filterOptions,
              status: this.filter.status
                ? [this.filter.status]
                : this.queryStatuses,
            },
            ...authHeader(),
            responseType: "blob",
          }
        );
        let headerLine = res.headers["content-disposition"];
        headerLine = decodeURIComponent(headerLine);
        const matches = /filename[^;=\n]*=(UTF-8['"]*)?([^;\n]*)/gi.exec(
          headerLine
        );
        const filename = (matches && matches[2]) || "Borrow_History.xlsx";
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(res.data);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
    },
  },

  created() {
    this.loadAsset();
  },
};
</script>

<style lang="scss" scoped>
#borrow-history {
  padding: 0.4em;

  .head-options {
    padding: 1em;
  }

  .btn-outline {
    p {
      margin: 0;
    }
    padding: 10px 15px;
    background-color: #fff;
    border: 2px solid;
    border-radius: 8px;
    &.primary-btn {
      color: #007afe;
      border-color: #007afe;
    }
    &.danger-btn {
      color: #f74949;
      border-color: #f74949;
    }
  }

  .filter {
    border-radius: 10px 10px 10px 10px !important;
    background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
    border: none;
    width: 40px;
    height: 40px;
    img {
      padding: 0.3rem;
      width: 40px;
    }
  }
}

.asset-info {
  text-align: left;
}
</style>
